
import "./OmniWellnessPage.scss";
import { useEffect, useState } from 'react';
import * as api from '../../apis/';
import { Link } from 'react-router-dom';
import { useSnackbar } from "notistack";
import InlineInformation from "../../components/Services/InlineInformation";
import Information from "../../components/Services/Information";
import { colorIt } from "../../utils/helpers";
import ServicesGroup from "../../components/Services/ServicesGroup";
import { Helmet } from "react-helmet";

const OmniWellnessPage = () => {
    const [services, setSerivces] = useState({} as {[Key: string]: {title: string, bullets: {type: string, text: string}[], contents: {type: string, text: string}[], oldPrice: number|null, price: number, stype: "one-time"|"subscription", loc: 0}[]});
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const onSuccess = (data:any) => {
        try{
            console.log(data);
            Object.keys(data.services).forEach((key:string) =>{
                data.services[key] = data.services[key].map((v:any)=>{return {...v, loc: parseFloat(v.loc)}}).sort((a:any,b:any)=>a.loc-b.loc).map((service:any)=>{
                    const bullets = [] as {type: string, text: string}[];
                    const contents = [] as {type: string, text: string}[];
                    service.contents.split("\n").forEach((str:string)=>{
                        if(str.substring(0,3)==="b::"){
                            bullets.push(colorIt(str.substring(3)));
                        } else if(str.substring(0,3)==="r::"){
                            contents.push({type: 'red', text: str.substring(3)});
                        } else {
                            contents.push(colorIt(str));
                        }
                    });
                    return {...service, bullets: bullets, contents: contents};
                });
            });
            setSerivces({...data.services});
            console.log(data.services);
        } catch(err){
            console.log(err);
        }
    }
    const onFailure = (data:any) => {
        closeSnackbar();
        enqueueSnackbar("Unable to fetch services at this time", {variant: "warning"});
    }
    useEffect(()=>{
        api.helpers.getApi(api.routes.getServices, onSuccess, onFailure);
    },[]);
    return (
        <div className="omni-wellness-content">
            <Helmet>
                <title>Phoenix Lounge - Services</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Omni Wellness, the Service offerings of the Phoenix Lounge Mobile Wellness Center."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Services" />
                <meta name="twitter:description" content="Omni Wellness, the Service offerings of the Phoenix Lounge Mobile Wellness Center." />
                <meta property="og:title" content="Phoenix Lounge - Services" />
                <meta property="og:description" content="Omni Wellness, the Service offerings of the Phoenix Lounge Mobile Wellness Center." />
            </Helmet>
            <h1>
                Services We Offer
            </h1>
            <div className={"services"}>
                {
                    Object.keys(services).map((serviceSetKey)=>(
                        <ServicesGroup sGroup={services[serviceSetKey]} sKey={serviceSetKey}/>
                    ))
                }
            </div>
            
        </div>
    )
};

export default OmniWellnessPage;
/* {
        title: "Menu and Recipe Audit",
        contents: `Starting at $450 · 4 hrs and up
        Starts with 8 menu items with nutritional information audited and provided in completeness to meet macronutrient information per FDA and USDA requirements. Additional recipes are $50/each.`
    }, {
        title: "Chef Consultant Service",
        contents: `Starting at $1,000 · 4 hrs
        Case by case specifics; Provides Sanitation Audit, Meal Review, Staff Efficacy Audit, Training As Needed, and Recommendations to improve all aspects of Food Service Operations`
    }*/
import { useRef, useState } from 'react';
import "./DefinitionItem.scss";
const DefinitionItem = ({hasLink, definition}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const thisRef = useRef<HTMLDivElement>(null);
    const handleClick = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
            <div className={"definition"}>
                <p onClick={handleClick}><strong>{definition.title}</strong></p>
                <div className={"definition-contents"} style={isOpen?{maxHeight: thisRef.current?.clientHeight!+6, transition: "all "+Math.min(1, Math.max((thisRef.current?.clientHeight!)/360, 0.1))+"s ease"}:{transition: "all "+Math.min(1, Math.max((thisRef.current?.clientHeight!)/200, 0.1))+"s ease"}}>
                    {
                        definition.contents.map((content:any)=>{
                            const g1 = content.replace(/(Citation):\s(http[^\s].+)/gi, "$1");
                            const g2 = content.replace(/(Citation):\s(http[^\s].+)/gi, "$2");
                            const g3 = content.replace(/(http[^\s].+)/gi, "$1");
                            const g4 = content.replace("=>", "");
                            const flag = content.indexOf("=>")!==-1;
                            if(g1==="Citation") return (<p key={content}><a href={g2} target="_blank" style={{fontSize: '0.6rem',}}>Citation</a></p>) 
                            else{
                                if(flag) return (<p key={content} style={{marginLeft: '20px'}}><span>{g4}</span></p>)
                                return (<p key={content}><span>{content}</span></p>)
                            }
                        })
                    }
                </div>
            </div>
            <div className={"hidden definition"}>
                <p><strong>{definition.title}</strong></p>
                <div ref={thisRef}>
                    {
                        definition.contents.map((content:any)=>{
                            const g1 = content.replace(/(Citation):\s(http[^\s].+)/gi, "$1");
                            const g2 = content.replace(/(Citation):\s(http[^\s].+)/gi, "$2");
                            const g3 = content.replace(/(http[^\s].+)/gi, "$1");
                            const g4 = content.replace("=>", "");
                            const flag = content.indexOf("=>")!==-1;
                            if(g1==="Citation") return (<p key={content}><a href={g2} target="_blank" style={{fontSize: '0.6rem',}}>Citation</a></p>) 
                            else{
                                if(flag) return (<p key={content} style={{marginLeft: '20px'}}><span>{g4}</span></p>)
                                return (<p key={content}><span>{content}</span></p>)
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
};

export default DefinitionItem;
import { Button } from "@mui/material";
import Information from "./Information";
import InlineInformation from "./InlineInformation";
import "./Services.scss";
import { useNavigate } from "react-router-dom";

const ServicesItem = ({serviceItem, registerable, onClick=(e:any):void=>{}}:any) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/user/services/'+serviceItem.id);
    }
    return (
        <div className={"service-item"+(serviceItem.state>1?" unpublished":"")} key={serviceItem.title} onClick={()=>{onClick(serviceItem.id)}}>
            <div className={serviceItem.stype.toString()}>
                <span>{serviceItem.title}</span>
            </div>
            <div className={"service-item-price"}>
                <span className={!serviceItem.oldPrice||serviceItem.oldPrice===serviceItem.price?"":"visible"}>{serviceItem.oldPrice&&serviceItem.oldPrice!==0&&serviceItem.oldPrice!==serviceItem.price?`($${serviceItem.oldPrice/100})`:``}</span>
                {serviceItem.price>=0?<span>${serviceItem.price/100}</span>:<span>VARIES</span>}
            </div>
            <div className="servie-item-content">
                <ul className="service-bullets">
                    {serviceItem.bullets.map((bullet:{className: string|undefined, type: string, text: string})=>(<li key={bullet.text}><InlineInformation className={bullet.className} color={bullet.type} text={bullet.text} /></li>))}
                </ul>
                    {serviceItem.contents.map((content:any) =>(<Information className={content.className} color={content.type} text={content.text} />))}
            </div>
            {
                registerable && (<div className="register-btn">
                    <Button variant="contained" onClick={handleClick}>Register</Button>
                </div>)
            }
        </div>
    )
}

export default ServicesItem;
import { useContext } from 'react';
import { Navigate } from "react-router-dom";
import { UserInfoContext } from "../../utils/context";


const ProtectedRoute = ({require=[] as number[], requireOr=[] as number[][], redirect='/', Component=(<div />), blacklist=[] as number[]}) => {
    const {userInfo} = useContext(UserInfoContext);
    let hasOr = false;
    for(let i = 0; i < requireOr.length; i+=1){
        let flag = true;
        for(let j = 0; j < requireOr[i].length; j+=1){
            if(userInfo.permissions[requireOr[i][j]]==="0"){
                flag = false;
            }
        }
        if(flag){
            hasOr = true;
            break;
        }
    }
    if(!hasOr && requireOr.length>0){
        return (<Navigate to={redirect}/>);
    }
    for(let i = 0; i < require.length; i+=1){
        if(userInfo.permissions[require[i]]==="0"){
            return (<Navigate to={redirect}/>);
        }
    }
    for(let i = 0; i < blacklist.length; i+=1){
        if(userInfo.permissions[blacklist[i]]==="1"){
            return (<Navigate to={redirect}/>);
        }
    }
    return Component;
};

export default ProtectedRoute;
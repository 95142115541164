import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import "./ContactForm.scss";
import { useContext, useRef, useState } from 'react';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import { useSnackbar } from 'notistack';
import * as api from '../../apis/';
import { isValidEmail, isValidPhone } from '../../utils/helpers';

const isValid = (v: string, str:string) => {
    if(v==="name"){
        return str.length>0;
    } else if(v==="email"){
        return isValidEmail(str)||isValidPhone(str)[0];
    } else if(v==="msg"){
        return str.length > 14;
    }
    return false;
}

const ContactForm = () => {
    const nameInput = useRef<HTMLInputElement>(null);
    const emailInput = useRef<HTMLInputElement>(null);
    const messageInput = useRef<HTMLTextAreaElement>(null);
    const [state, setState] = useState({
        name: {val: '', isError: false},
        email: {val: '', isError: false},
        msg: {val: '', isError: false},
    } as any);
    const [isPosting, setIsPosting] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleChange = (v:string) => (e: any) => {
        setState({...state, [v]: {...state[v], val: e.target.value, isError: !(!state[v].isError||isValid(v, e.target.value))}});
    }
    const onSuccess = (data:any) => {
        setState({
            name: {val: '', isError: false},
            email: {val: '', isError: false},
            msg: {val: '', isError: false},
        });
        setIsPosting(false);
        closeSnackbar();
        enqueueSnackbar("Thank you for reaching out, your message has been sent.", {variant: 'success'});
    }
    const onFailure = (data:any) => {
        setIsPosting(false);
        closeSnackbar();
        enqueueSnackbar("Your message could not be sent, try again or try another method.", {variant: 'error'});
    }
    const handleSubmit = (e:any) => {
        e.preventDefault();
        if(isPosting) return;
        if(!isValid("email", state.email.val)) {
            console.log("invalid",state.email.val);
            setState({...state, email: {...state.email, isError: true}});
            return;
        }
        setIsPosting(true);
        api.helpers.postApi(api.routes.sendAnonMessage, {name: state.name.val, email: isValidEmail(state.email.val)?state.email.val:isValidPhone(state.email.val)[1], msg: state.msg.val}, onSuccess, onFailure);
    }
    return (
        <section className="contact-us-area">
            <div className="contact-content">
                <h2>Contact Us</h2>
                <p>If you would like to learn more about our services, practices, offerings or to schedule a consultation, please contact us by phone, email, or using the form below.</p>
            </div>
            <div className="container">
                <div className="contactInfo">
                    <div className="box">
                        <div className="icon"><LocationCityIcon /></div>
                        <div className="text">
                            <h3>Service Location</h3>
                            <p>Dallas / Fort Worth area.</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><PhoneIcon /></div>
                        <div className="text">
                            <h3>Phone</h3>
                            <p>+1 (623) 251-9868</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><MailOutlineIcon /></div>
                        <div className="text">
                            <h3>Email</h3>
                            <p>wellness.dean@gmail.com</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="facebook-icon"></div>
                        <div className="instagram-icon"></div>
                        <div className="linkedin-icon"></div>
                    </div>
                </div>
                <div className="contactForm">
                    <form onSubmit={handleSubmit}>
                        <h2>Send Message</h2>
                        <div className="inputBox">
                            <input type="text" name="name" required ref={nameInput} value={state.name.val} onChange={handleChange("name")} className={state.name.isError?"error":""}/>
                            <span onClick={()=>{nameInput.current?.focus()}}>Full Name</span>
                        </div>
                        <div className={"inputBox"+(state.email.isError?" error":"")}>
                            <input type="text" name="email" required ref={emailInput} value={state.email.val} onChange={handleChange("email")} className={state.email.isError?"error":""}/>
                            <span onClick={()=>{emailInput.current?.focus()}}>Email or Phone</span>
                            {state.email.isError?(<span onClick={()=>{emailInput.current?.focus()}} className={"error-msg"}> - Invalid email or phone</span>):""}
                        </div>
                        <div className="inputBox">
                            <textarea rows={5} required ref={messageInput} value={state.msg.val} onChange={handleChange("msg")} className={state.msg.isError?"error":""} maxLength={300} minLength={15}></textarea>
                            <span onClick={()=>{messageInput.current?.focus()}}>Please detail your request...</span>
                        </div>
                        <div className="inputBox">
                            <input type="submit" name="submit-btn" value="Send"/>
                        </div>
                        <div className="secure-msg">
                            <LockTwoToneIcon />
                            <span className="info-msg">Messages are encrypted and kept confidential.</span>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default ContactForm;
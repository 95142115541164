
import "./Footer.scss";
const Footer = () => {
    return (
        <div className="footer">
            <span>Copyright 2023 Phoenix Lounge</span>
        </div>
    )
};

export default Footer;
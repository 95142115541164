import { createContext } from 'react';
export const UserInfoContext = createContext({userInfo: {
    id: 0,
    firstname: "Guest",
    lastname: "Guest",
    permissions: "0000000000",
    sessionId: "sk83hek7",
}, setUserInfo: {} as any});
export const StoreContext = createContext({store: {
    initialLoad: false,
    theme: 'light',
}, dispatch: {} as any});
export const DashboardContext = createContext({
    uservices: [] as any[],
    recentActivity: {} as any,
    users: [] as any[],
    isLoading: true,
    errors: [] as any[]
});
export const AdminDashboardContext = createContext({
    uservices: [] as any[],
    recentActivity: {} as any,
    users: [] as any[],
    isLoading: true,
    errors: [] as any[]
});
export const AdminUDashboardContext = createContext({
    uservices: [] as any[],
    recentActivity: {} as any,
    dataUpToDate: true,
    isLoading: true,
    errors: [] as any[]
});
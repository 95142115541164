import React from 'react';
import "./PageNotFound.scss";
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
    return (
        <div className="page-not-found-container">
            <Helmet>
                <title>Phoenix Lounge - 404 Page not found.</title>
                <meta name="robots" content="noindex, follow" />
                <meta
                    name="description"
                    content="Page not found."
                />
                <meta name="twitter:title" content="Phoenix Lounge - 404 Page not found." />
                <meta name="twitter:description" content="Page not found." />
                <meta property="og:title" content="Phoenix Lounge - 404 Page not found." />
                <meta property="og:description" content="Page not found." />
            </Helmet>
            <h1>404 Nutrition not found.</h1>
        </div>
    )
};

export default PageNotFound;
const Information = ({className, color, text}: {className: string|undefined, color: string, text:string}) => {
    const idx = text.indexOf("(");
    const end = text.indexOf(")");
    if(idx!=-1&&end!=-1&&end>idx){
        return <p className={className} style={{color: color}}>{text.substring(0,idx)}<span style={{fontSize: "0.6rem"}}>{text.substring(idx,end+1)}</span>{text.substring(end+1)}</p>
    }
    return <p className={className} style={{color: color}}>
        {text}
    </p>
}

export default Information;
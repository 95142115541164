export const isValidPhone = (str: string) => {
    const digits = str.match(/\d/g);//get all digits in string
    return digits?(
        [(digits.length===10&&digits[0]!=='0')||(digits.length===11&&digits[0]==='1'&&digits[1]!=='1'), digits.join("").substring(digits.length-10)]
        ):[false, ""];
    //If there are no digits just return false and an empty string. Otherwise return true/false if is US/Canada phone like and that number with only digits.
}
export const hasEightChars = (str: string) => {
    return /.{8,}/.test(str);
}
export const hasNumbers = (str: string) => {
    return /.*\d.*/.test(str);
}
export const hasLower = (str: string) => {
    return /[a-z]/.test(str);
}
export const hasUpper = (str: string) => {
    return /[A-Z]/.test(str);
}
export const hasSpecial = (str: string) => {
    return /.*[@$!%*#?&].*/.test(str);
}
export const isValidEmail = (str: string) => {
    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str);
}
export const isValidPassword = (str: string) => {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(str);
}
export const handleAuthErrors = (message: {type: string, msg: string}) => {
    return message.type==="PINERR" || message.type==="SIGNEDOUT";
}
export const colorIt = (str:string) => {
    const reg = /^([A-Za-z]+)::/;
    const matchIt = str.match(reg); 
    if(matchIt){
        if(matchIt[1]==="primary"){
            return {className: 'primaryColor', type: 'unset', text: str.substring(matchIt[0].length)}
        } else if(matchIt[1]==="secondary"){
            return {className: 'secondaryColor', type: 'unset', text: str.substring(matchIt[0].length)}
        }
        return {type: matchIt[1], text: str.substring(matchIt[0].length)};
    }
    return {type: 'black', text: str};
}

import { useContext, useEffect, useRef, useState } from "react";
import "./SigninForm.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { UserInfoContext } from "../../utils/context";
import * as api from "../../apis/";
import { hasLower, hasNumbers, hasSpecial, hasUpper, isValidEmail, isValidPassword, isValidPhone } from "../../utils/helpers";
import { Checkbox, FormControlLabel } from "@mui/material";

const isValid = (v: string, str:string) => {
    if(v==="name"){
        return str.length>0;
    } else if(v==="email"){
        return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str)
    } else if(v==="msg"){
        return str.length > 14;
    }
    return false;
}

const RegisterForm = () => {
    const firstnameInput = useRef<HTMLInputElement>(null);
    const lastnameInput = useRef<HTMLInputElement>(null);
    const dobInput = useRef<HTMLInputElement>(null);
    const emailInput = useRef<HTMLInputElement>(null);
    const phoneInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const confPasswordInput = useRef<HTMLInputElement>(null);
    const [gender, setGender] = useState("");
    const navigate = useNavigate();
    const [isPosting, setIsPosting] = useState(false);
    const {userInfo, setUserInfo} = useContext(UserInfoContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const handleSuccess = (data:any) => {
        setUserInfo(data.user);
        sessionStorage.setItem("authToken", data.authToken);
        navigate("/verify/email");
    }
    const handleFailure = (data:any) => {
        closeSnackbar();
        enqueueSnackbar("Something went wrong, try again later.", {variant: 'error'});
        setIsPosting(false);
    }
    const handleSubmit = (e:any) => {
        e.preventDefault();
        const values = {
            firstname: firstnameInput.current?.value,
            lastname: lastnameInput.current?.value,
            dob: dobInput.current?.value,
            gender: gender,
            email: emailInput.current?.value,
            phone: isValidPhone(phoneInput.current?.value!),
            password: passwordInput.current?.value,
        }
        if(passwordInput.current?.value!==confPasswordInput.current?.value){
            closeSnackbar();
            enqueueSnackbar("Passwords do not match!", {variant: 'error'});
            return;
        }
        if(!isValidEmail(values.email!)) {
            closeSnackbar();
            enqueueSnackbar("Invalid email", {variant: 'error'});
            return;
        }
        if(gender===""){
            closeSnackbar();
            enqueueSnackbar("Please select a gender", {variant: 'error'});
            return;
        }
        if(!values.phone[0]){
            closeSnackbar();
            enqueueSnackbar("Invalid phone number.", {variant: 'error'});
            return;
        }
        if(!acceptTerms){
            closeSnackbar();
            enqueueSnackbar("You cannot use the services without accepting the Terms and Conditions", {variant: 'error'});
        }
        if(!acceptPrivacy){
            closeSnackbar();
            enqueueSnackbar("You cannot use the services without accepting the Privacy Policy", {variant: 'error'});
        }
        if(!isValidPassword(values.password!)){
            if(!hasLower(values.password!)){
                enqueueSnackbar("Password must contain lowercase letter");
            } else if(!hasUpper(values.password!)){
                enqueueSnackbar("Password must contain uppercase letter");
            } else if(!hasNumbers(values.password!)){
                enqueueSnackbar("Password must contain a number");
            } else if(!hasSpecial(values.password!)){
                enqueueSnackbar("Password must contain a special character");
            }
            return;
        }
        setIsPosting(true);
        api.helpers.postApi(api.routes.register, {
            firstname: firstnameInput.current?.value,
            lastname: lastnameInput.current?.value,
            dob: dobInput.current?.value,
            gender: gender,
            email: emailInput.current?.value, 
            phone: values.phone[1],
            password: passwordInput.current?.value,
        }, handleSuccess, handleFailure);
    }
    const handleGender = (n:string) => (e:any) => {
        setGender(n);
    }
    useEffect(()=>{
        if(userInfo.permissions[0]==="1") navigate('/');
    },[userInfo])
    return (
        <div className="signin-area">
            <div className="signinForm">
                <form onSubmit={handleSubmit}>
                    <h2>Register</h2>
                    <div className="register-inputs">
                        <div className="input-row">
                            <div className="inputBox">
                                <input type="text" name="firstname" required ref={firstnameInput} minLength={1}/>
                                <span onClick={()=>{firstnameInput.current?.focus()}}>First Name</span>
                            </div>
                            <div className="inputBox">
                                <input type="text" name="lastname" required ref={lastnameInput} minLength={1}/>
                                <span onClick={()=>{lastnameInput.current?.focus()}}>Last Name</span>
                            </div>
                        </div>
                        <div className="input-row">
                            <fieldset name="gender-select" className="gender-input">
                                <div onClick={handleGender("Male")}>
                                    <input type="radio" name="gender-select" checked={gender==="Male"}/>
                                    <label>Male</label>
                                </div>
                                <div onClick={handleGender("Female")}>
                                    <input type="radio" name="gender-select" checked={gender==="Female"}/>
                                    <label>Female</label>
                                </div>
                            </fieldset>
                            <div className="dob-area">
                                <span>DOB: </span>
                                <input type="date" required ref={dobInput}/>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="inputBox">
                                <input type="text" name="email" required ref={emailInput}/>
                                <span onClick={()=>{emailInput.current?.focus()}}>Email</span>
                            </div>
                            <div className="inputBox">
                                <input type="text" name="phone" required ref={phoneInput}/>
                                <span onClick={()=>{phoneInput.current?.focus()}}>Phone</span>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="inputBox">
                                <input type="password" required ref={passwordInput} minLength={8}/>
                                <span onClick={()=>{passwordInput.current?.focus()}}>Password</span>
                            </div>
                            <div className="inputBox">
                                <input type="password" required ref={confPasswordInput} minLength={8}/>
                                <span onClick={()=>{confPasswordInput.current?.focus()}}>Confirm Password</span>
                            </div>
                        </div>
                        
                    </div>
                    <FormControlLabel control={<Checkbox value={acceptTerms} onChange={(e:any)=>{setAcceptTerms(e.target.checked)}}/>}
                        label={
                            (<p style={{color: 'white'}}>
                                I have read and agree to the <Link to="/terms" target="_blank" style={{color: 'blue'}}>Terms and Conditions</Link>
                            </p>)
                        }
                    />
                    <FormControlLabel control={<Checkbox value={acceptPrivacy} onChange={(e:any)=>{setAcceptPrivacy(e.target.checked)}}/>}
                        label={
                            (<p style={{color: 'white'}}>
                                I have read and accept the <Link to="/privacy" target="_blank" style={{color: 'blue'}}>Privacy Policy</Link>
                            </p>)
                        }
                    />
                    <div className="inputBox">
                        <input type="submit" name="submit-btn" value="Register" disabled={isPosting}/>
                    </div>
                    <span className="other-msg">Already have an account? <span className="link-text" onClick={()=>{navigate("/signin")}}>Sign In</span>!</span>
                    <span className="error-msg">Sign in and Registration coming soon.</span>
                </form>
            </div>
        </div>
    )
};

export default RegisterForm;
import {Suspense, createContext, lazy, useEffect} from 'react';
import './App.css';
import { io } from "socket.io-client";
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useReducer } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LandingPage from './pages/LandingPage/LandingPage';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import SigninPage from './pages/SigninPage/SigninPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import OmniWellnessPage from './pages/OmniWellnessPage/OmniWellnessPage';
import DefinitionsPage from './pages/DefinitionsPage/DefinitionsPage';
import { UserInfoContext } from './utils/context';
import { StoreType } from './utils/@types';
import AboutPage from './pages/AboutPage/AboutPage';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { loadStripe } from '@stripe/stripe-js';
import * as api from './apis/';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { ThemeProvider, createTheme } from '@mui/material';
import PrivacyPolicyPage from './pages/PrivacyPolicy/PrivacyPolicy';
import { Helmet } from 'react-helmet';
import VerifyEmailPage from './pages/VerifyEmailPage/VerifyEmailPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
const AdminRoutes = lazy(()=>import('./components/Routes/AdminRoutes'));
const AuthRoutes = lazy(()=>import('./components/Routes/AuthRoutes'));
const TermsAndConditions = lazy(()=>import('./pages/Terms/TermsMain'));

const storeReducer = (state: StoreType, action: any) => {
  switch(action.type){
    case "INITIAL_LOAD":
      sessionStorage.setItem("pageStore", JSON.stringify({...state, initialLoad: true}));
      return {...state, initialLoad: true};
    default:
      return {...state};
  }
}

const messagesReducer = (state: any, action: any) => {
  let messages = [];
  let idx = 0;
  switch(action.type){
    case "ADD_ENTRY":
      return {...state, services: {...state.services, [action.service]: {...state.services[action.service], msgCount: state.services[action.service].msgCount+1, messages: [...state.services[action.service].messages, action.payload]}}};
    case "ADD_MULTIPLE":
      return {...state, services: {...state.services, [action.service]: {...state.services[action.service], msgCount: state.services[action.service].msgCount+action.payload.length, messages: state.services[action.service].messages.concat(action.payload)}}};
    case "UPDATE_TYPE":
      messages = [...state.services[action.service].messages];
      idx = messages.findIndex((v)=>v.id===action.payload.id);
      messages.splice(idx,1, {...state.services[action.service].messages[idx], mtype: action.payload.mtype});
      return {...state, services: {...state.services, [action.service]: {...state.services[action.service], messages: messages}}}
    case "ADD_SERVICE":
      return {...state, services: {...state.services, [action.service]: action.payload}};
    default:
      return {...state};
  }
}

const initStore = {
  initialLoad: false,
  theme: 'light'
}

const getStripePromise = () => {
  const key = document.getElementById("stripeKey")?.dataset.key;
  if(!key || key==="") return null;
  return loadStripe(key);
}

const stripePromise = getStripePromise();

export const initUserInfo = {
  id: 0,
  firstname: "Guest",
  lastname: "Guest",
  permissions: "0000000000",
  sessionId: '000000'
}
export type serviceMessagesType = {
  [key: string|number]: {
    msgCount: number,
    messages: {type: string, content: string, from: string, sent: number}[]
  }
}

export const initMessages = {
  services: {}
} as {services: serviceMessagesType}

export const MessagesContext = createContext({messages: initMessages, dispatchMessages: {} as any});
export const SocketConContext = createContext({isConnected: false, setIsConnected: {} as any});

export const socket = io({autoConnect: false});

function App() {
  const [userInfo, setUserInfo] = useState(initUserInfo);
  const [isConnected, setIsConnected] = useState(false);
  const handleSuccess = (data:any) => {
    setUserInfo(data.user);
    sessionStorage.setItem("user", JSON.stringify(data.user));
    sessionStorage.setItem("authToken", data.authToken);
    socket.connect();
    dispatch({type: "INITIAL_LOAD", payload: ''});
  }
  const handleFailure = (data:any) => {
    sessionStorage.setItem("user", JSON.stringify(userInfo));
    sessionStorage.setItem("authToken", "a")
    dispatch({type: "INITIAL_LOAD", payload: ''});
  }
  const theme = createTheme({
    palette: {
      primary: {
        light: '#20bfff',
        main: '#009fff',
        dark: '#008fef',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#33ffff',
        main: '#00ffff',
        dark: '#00e0e0',
        contrastText: '#000000',
      },
    },
  });
  const [store, dispatch] = useReducer(storeReducer, initStore);
  const [messages, dispatchMessages] = useReducer(messagesReducer, initMessages);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  useEffect(()=>{
    api.helpers.getAccessApi(api.routes.getdetails, handleSuccess, handleFailure);
    const onConnect = () => {
      setIsConnected(true);
    }
    const onDisconnect = () => {
      setIsConnected(false);
    }
    const handleMsg = (data:string) => {
      const parsedData = JSON.parse(data);
      //parsedData will have a sid (int/string)
      //parsedData will have a msg
      //msg will have a type, content, msg
      dispatchMessages({type: "ADD_ENTRY", service: parsedData.sid, payload: parsedData.msg});
    }
    const onServiceReg = (data:string) => {
      const parsedData = JSON.parse(data);
      dispatchMessages({type: "ADD_SERVICE", service: parsedData.sid, payload: []});
    }
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('servicereg', onServiceReg);
    socket.on('msg', handleMsg);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('servicereg', onServiceReg);
      socket.off('msg', handleMsg);
    }
  }, []);
  return (
      <>
        <Router>
          <UserInfoContext.Provider value={{userInfo: userInfo, setUserInfo: setUserInfo}}>
          <MessagesContext.Provider value={{messages:messages, dispatchMessages:dispatchMessages}}>
          <SocketConContext.Provider value={{isConnected: isConnected, setIsConnected: setIsConnected}}>
          <SnackbarProvider><ThemeProvider theme={theme}>
          <Header/>
          {
            store.initialLoad?(
              <Routes>
                <Route path="/" element={<LandingPage />}/>
                <Route path="/verify/email" element={<VerifyEmailPage />} />
                <Route path="/verify/email/:code" element={<VerifyEmailPage />} />
                <Route path="/services" element={<OmniWellnessPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/definitions" element={<DefinitionsPage />} />
                <Route path="/signin" element={(userInfo.permissions[0]==="1"?(<Navigate to="/" />):(<SigninPage />))} />
                <Route path="/register" element={(userInfo.permissions[0]==="1"?(<Navigate to="/" />):(<RegisterPage />))} />
                <Route path="/forgot-password" element={(<Suspense fallback={(<CircularProgress />)}><ForgotPasswordPage /></Suspense>)} />
                <Route path="/user/*" element={(<Suspense fallback={(<CircularProgress />)}><AuthRoutes stripePromise={stripePromise}/></Suspense>)} />
                <Route path="/admin/*" element={<ProtectedRoute requireOr={[[3],[4],[5],[6],[7],[8],[9]]} Component={<Suspense fallback={(<CircularProgress />)}><AdminRoutes /></Suspense>} />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/terms" element={<Suspense fallback={<CircularProgress />}><TermsAndConditions /></Suspense>} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            ):(
              <CircularProgress className="initialLoadIndicator"></CircularProgress>
            )
          }
          <Footer /></ThemeProvider>
          </SnackbarProvider>
          </SocketConContext.Provider>
          </MessagesContext.Provider>
          </UserInfoContext.Provider>
        </Router>
      </>
  );
}

export default App;

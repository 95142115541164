const apiBase = "/api"

export const getDefinitions = apiBase+"/definitions";
export const getOneDefinition = apiBase+"/definitions/";
export const getServices = apiBase+"/services";
export const getOneService = apiBase+"/services/";
export const sendAnonMessage = apiBase+"/contact-message";

export const signin = apiBase+"/auth/signin";
export const register = apiBase+"/auth/register";
export const getdetails = apiBase+"/auth/details";
export const signout = apiBase+"/auth/signout";
export const forgotpass = apiBase+"/auth/forgot-password";
export const confirmresetcode = apiBase+"/auth/confirm-pass-code";
export const resetpasswithcode = apiBase+"/auth/confirm-password-reset";

export const getClientSecret = apiBase+"/payments/setupIntent";
export const confirmPaymentSetup = apiBase+"/payments/confirmSetup";

export const getLatestUserInfo = apiBase+"/users/user-info";
export const getLatestUserInfoDate = apiBase+"/users/user-info-date";
export const getRecentActivity = apiBase+"/users/recent-activity";
export const getUserPrefs = apiBase+"/users/prefs";
export const getWeights = apiBase+"/users/weights";
export const addWeight = apiBase+"/users/weight";
export const getAccountInfo = apiBase+"/users/account-info";
export const updatePassword = apiBase+"/users/update-password";
export const registerService = apiBase+"/users/register-service";

export const getServiceMessages = apiBase+"/messages/service/";
export const sendMessage = apiBase+"/messages/send/";
export const sendAdminMessage = apiBase+"/messages/admin/send/";
export const sendPaymentRequestAnswer = apiBase+"/messages/payment/answer/";

export const downloadFile = apiBase+"/files/";

export const updateAccountInfo = apiBase+"/users/update-account-info";
export const getUDashboardServices = apiBase+"/users/dashboard/services";

export const getSiteSettings = apiBase+"/admin/sitesettings";
export const updateSiteSettings = apiBase+"/admin/updatesettings";
export const getServicesAdmin = apiBase+"/admin/services";
export const getOneServiceAdmin = apiBase+"/admin/services/";
export const getUsersAdmin = apiBase+"/admin/users";
export const getAdminRecentActivity = apiBase+"/admin/recent-activity";
export const getAdminDashboardServices = apiBase+"/admin/dashboard/services";
export const getAdminUserRecentActivity = apiBase+"/admin/users/recent-activity/";
export const getAdminUserDashboardServices = apiBase+"/admin/users/dashboard/services/";
export const getAdminUserWeights = apiBase+"/admin/users/weights/";
export const getAdminUserGeneralFormData = apiBase+"/admin/users/generalformdata/";

export const updateUserWeights = apiBase+"/admin/users/update/weights";
export const updateUserAttribute = apiBase+"/admin/users/update/attr/";

export const removeDefinition = apiBase+"/admin/definitions/remove";
export const addDefinition = apiBase+"/admin/definitions/add";
export const editDefinition = apiBase+"/admin/definitions/edit";
export const reorderDefinitions = apiBase+"/admin/definitions/reorder";

export const removeService = apiBase+"/admin/services/remove";
export const addService = apiBase+"/admin/services/add";
export const editServices = apiBase+"/admin/services/edit";
export const reorderServices = apiBase+"/admin/services/reorder";

export const getDashboardServices = apiBase+"/admin/dashboard/services";

export const getAnonymousMessages = apiBase+"/admin/anonmessages";
export const getAnonymousMessage = apiBase+"/admin/anonmessages/";

export const verifyEmail = apiBase+"/users/verify-email/";
export const sendEmailVerification = apiBase+"/users/send-email-verification";
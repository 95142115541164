
import "./LandingPage.scss";
import ImageGallery from 'react-image-gallery';
import BadgeImg from '../../assets/badge.webp';
import InfoLeft from '../../assets/infoleft.webp';
import InfoLeftThumb from '../../assets/infoleft-low-res.webp';
import InfoRightThumb from '../../assets/inforight-low-res.webp';
import InfoRight from '../../assets/inforight.webp';
import DonateQR from '../../assets/donate-qr.webp';
import { Link, useNavigate } from 'react-router-dom';
import Soundbite from "../../components/Soundbite/Soundbite";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";

const LandingPage = () => {
    const galleryImages = [
        {
            original: InfoLeft,
            thumbnail: InfoLeftThumb
        },
        {
            original: InfoRight,
            thumbnail: InfoRightThumb
        }
    ]
    const navigate = useNavigate();
    return (
        <div className={"landing-page-container"}>
            <Helmet>
                <title>Phoenix Lounge - Mobile Wellness</title>
                <meta name="robots" content="index, follow" />
                <meta
                    name="description"
                    content="Phoenix Lounge is the mobile wellness that is patient-centered and focuses on fixing malnutrition through Christ focused love."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Mobile Wellness" />
                <meta name="twitter:description" content="Phoenix Lounge is the mobile wellness that is patient-centered and focuses on fixing malnutrition through Christ focused love." />
                <meta property="og:title" content="Phoenix Lounge - Mobile Wellness" />
                <meta property="og:description" content="Phoenix Lounge is the mobile wellness that is patient-centered and focuses on fixing malnutrition through Christ focused love." />
            </Helmet>
            <Soundbite className={"primary-soundbite"} firstText={"Providing Compassionate Holistic"} secondText={"Medical Nutrition Therapy"}/>
            <div className={"section highlightleft"}>
                <div className={"highlight-spacer"}>
                    
                </div>
                <div className={"message"}>
                    <h1>What is Phoenix Lounge?</h1>
                    <p>The Phoenix Lounge, Operated by Johnny Dean, RD, LDN, Chef Minister and Clinical Wellness Dietitian</p>
                    <p>The Licensed and Registered Expert and an Independent Authority on Food Service, Medical Nutrition Therapy, Human Wellness and Dietetics</p>
                    {/*<img className="info-right" src={InfoRight} width="1080px" height="525px" style={{width: "50%"}}/>
                    <img className="info-left" src={InfoLeft} width="1080px" height="525px" style={{width: "50%"}}/>*/}
                </div>
            </div>
            <div className="badge-display">
                <Link to="https://www.cdrnet.org/RDN"><img src={BadgeImg} alt="" /></Link>
            </div>
            <div className={"section"}>
                <Soundbite className={"primary-soundbite"} firstText={"Extending Wellness Reach"} secondText={"Through Mobile Practice"}/>
                <div className="bubbledown">
                    <Button variant={"contained"} color={"primary"} onClick={()=>{navigate('contact-us')}}><Link to="/contact-us">Contact Us</Link></Button>
                </div>
                <ImageGallery items={galleryImages} autoPlay lazyLoad/>
            </div>
            <div className={"section highlightright"}>
                <div className={"highlight-spacer"}>
                    
                </div>
                <div className={"message"}>
                    <p>Where Medicine and Nutrition Harmonizes in Support of Life, Liberty, and the Pursuit of Happiness.</p>
                    <p>With the Phoenix Lounge we simplify the data.</p>
                    <p>Letting you know what the numbers mean.</p>
                </div>
            </div>
            <div className={"section donate-area"}>
                <div className={"message"}>
                    <h3>Want to support us?</h3>
                    <p>Donate or refer your friends.</p>
                    <p>Partnerships? Gift Services?</p>
                    <Button variant={"contained"} color={"primary"} onClick={()=>{navigate('contact-us')}}><Link to="/contact-us">Contact Us</Link></Button>
                </div>
                <div className={"image-content"}>
                    <Link to="https://donate.stripe.com/cN25kvbXz6HF2CkeUU"><img src={DonateQR} alt={""} className={"border-five-pct"} style={{width: '280px'}}/></Link>
                </div>
            </div>
        </div>
    )
};

export default LandingPage;
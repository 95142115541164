import { Helmet } from "react-helmet";
import ForgotPasswordForm from "../../components/SigninForm/ForgotPasswordForm";
import "./ForgotPasswordPage.scss";

const ForgotPasswordPage = () => {
    return (
        <div className="signin-page-container">
            <Helmet>
                <title>Phoenix Lounge - Forgot Password</title>
                <meta name="robots" content="noindex, follow" />
                <meta
                    name="description"
                    content="Forgot your password? Reset it here - Phoenix Lounge."
                />
                <meta name="twitter:title" content="Phoenix Lounge - Forgot Password" />
                <meta name="twitter:description" content="Forgot your password? Reset it here - Phoenix Lounge." />
                <meta property="og:title" content="Phoenix Lounge - Forgot Password" />
                <meta property="og:description" content="Forgot your password? Reset it here - Phoenix Lounge." />
            </Helmet>
            <ForgotPasswordForm />
        </div>
    )
}

export default ForgotPasswordPage;
import React, { useRef } from "react";
import ServicesItem from "./ServicesItem";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "./Services.scss";

const ServicesGroup = ({sKey, sGroup, registerable, nonav=false, notitle=false, onClick=(e:any):void=>{}}: any) => {
    const thisRef = useRef<HTMLDivElement>(null);
    const handleLeftScroll = () => {
        const winWid = window.innerWidth;
        if(winWid>=320&&winWid<580){
            thisRef.current?.scrollBy(-(240+(winWid-280)/2),0);
        } else if(winWid>=580&&winWid<840){
            thisRef.current?.scrollBy(-(240+(winWid-520)/3),0);
        } else if(winWid>=840&&winWid<1100){
            thisRef.current?.scrollBy(-(240+(winWid-760)/4),0);
        } else{
            thisRef.current?.scrollBy(-260,0);
        }
    }
    const handleRightScroll = () => {
        const winWid = window.innerWidth;
        if(winWid>=320&&winWid<580){
            thisRef.current?.scrollBy((240+(winWid-280)/2),0);
        } else if(winWid>=580&&winWid<840){
            thisRef.current?.scrollBy((240+(winWid-520)/3),0);
        } else if(winWid>=840&&winWid<1100){
            thisRef.current?.scrollBy((240+(winWid-760)/4),0);
        } else{
            thisRef.current?.scrollBy(260,0);
        }
    }
    console.log(sGroup)
    return (
        <>
            {notitle?'':(<h2>{sKey} Services</h2>)}
            <div ref={thisRef} className={"category-container"+(nonav&&notitle?' centerit':'')}>
                {
                    sGroup.map((serviceItem:any)=>(
                        <ServicesItem serviceItem={serviceItem} key={serviceItem.loc} registerable={registerable} onClick={onClick}/>
                    ))
                }
            </div>
            {nonav?'':(<><div className="arrow-scroller left-arrow-scroller" onClick={handleLeftScroll}>
                <KeyboardArrowLeftIcon />
            </div>
            <div className="arrow-scroller right-arrow-scroller" onClick={handleRightScroll}>
                <KeyboardArrowRightIcon />
            </div>
            </>)}
        </>
    )
};

export default ServicesGroup;
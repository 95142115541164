
import { useContext, useEffect, useRef, useState } from "react";
import "./SigninForm.scss";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../utils/context";
import * as api from '../../apis/';
import { useSnackbar } from "notistack";
import { isValidEmail, isValidPhone } from "../../utils/helpers";
const SigninForm = () => {
    const emailInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [isPosting, setIsPosting] = useState(false);
    const {userInfo, setUserInfo} = useContext(UserInfoContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSuccess = (data:any) => {
        setUserInfo(data.user);
        sessionStorage.setItem("authToken", data.authToken);
        navigate('/');
    }
    const handleFailure = (data:any) => {
        closeSnackbar();
        enqueueSnackbar("Invalid Email/Phone and Password Combination.", {variant: 'error'});
        setIsPosting(false);
    }
    const handleSubmit = (e:any) => {
        e.preventDefault();
        let submitEmail = emailInput.current?.value;
        if(!isValidEmail(submitEmail!)){
            if(!(isValidPhone(submitEmail!)[0])){
                closeSnackbar();
                enqueueSnackbar("Invalid Email or Phone", {variant: 'error'});
                return;
            }
            submitEmail = isValidPhone(submitEmail!)[1] as string;
        }
        setIsPosting(true);
        api.helpers.postApi(api.routes.signin, {email: submitEmail, password: passwordInput.current?.value}, handleSuccess, handleFailure);
    }
    useEffect(()=>{
        if(userInfo.permissions[0]==="1") navigate('/');
    },[userInfo])
    return (
        <div className="signin-area">
            <div className="signinForm">
                <form onSubmit={handleSubmit}>
                    <h2>Sign In</h2>
                    <div className="inputBox">
                        <input type="text" name="emailphone" required ref={emailInput}/>
                        <span onClick={()=>{emailInput.current?.focus()}}>Email or Phone</span>
                    </div>
                    <div className="inputBox">
                        <input type="password" required ref={passwordInput} />
                        <span onClick={()=>{passwordInput.current?.focus()}}>Password</span>
                    </div>
                    <div className="inputBox">
                        <input type="submit" name="submit-btn" value="Sign In" disabled={isPosting}/>
                    </div>
                    <span className="other-msg">Don't have an account? <span className="link-text" onClick={()=>{navigate("/register")}}>Register</span>!</span>
                    <span className="other-msg"><span className="link-text" onClick={()=>{navigate("/forgot-password")}}>Forgot Password</span>!</span>
                </form>
            </div>
        </div>
    )
};

export default SigninForm;
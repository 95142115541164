
import Logo from "../../assets/svg/Logo";
import "./Header.scss"
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useContext } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { UserInfoContext } from "../../utils/context";
const Header = () => {
    const navigate = useNavigate();
    const [linksVisible, setLinksVisible] = useState(false);
    const {userInfo} = useContext(UserInfoContext);
    const links = userInfo.sessionId!=="000000"?(userInfo.permissions[4]==="1"?([
        {to: '/admin/dashboard', title: "Dashboard"},
        {to: '/admin/services', title: "Services"},
        {to: '/admin/definitions', title: 'Definitions'},
        {to: '/user/account', title: 'My Account'}
    ]):([
        {to: '/user/dashboard', title: "Dashboard"},
        {to: '/user/services', title: "Services"},
        {to: '/definitions', title: "Definitions"},
        {to: '/contact-us', title: "Contact Us"},
        {to: '/user/account', title: "My Account"}
    ])):([
        {to: '/services', title: "Services"}, 
        {to: '/about', title: "About"},
        {to: '/definitions', title: "Definitions"},
        {to: '/contact-us', title: "Contact Us"}, 
        {to: '/signin', title: "Sign In"}
    ]);
    return (
        <>
        <div className="header">
            <nav>
                <a className="logo-link" onClick={()=>{navigate('/');}}><Logo /></a>
                <div className={"collapse-links"}>
                    <div className="mobile-header-btn visible">
                        <div onClick={()=>setLinksVisible(true)}>
                            <MenuIcon />
                        </div>
                    </div>
                    <ul className={"page-links"}>
                        {
                            links.map((link)=>(
                                <li key={link.to}>
                                    <NavLink
                                        className={(navData) => (navData.isActive ? 'active' : '')}
                                        to={link.to}
                                        onClick={()=>{setLinksVisible(false);}}
                                    >
                                        <Button onClick={()=>{navigate(link.to); setLinksVisible(false);}}>{link.title}</Button>
                                    </NavLink>
                                    {/*<Button onClick={()=>{navigate(link.to); setLinksVisible(false);}}>{link.title}</Button>*/}
                                </li>
                            ))
                        }
                    </ul>
                    <a className={"close-hamburger-btn"+(linksVisible?" visible":"")} onClick={()=>setLinksVisible(false)}>
                        <KeyboardDoubleArrowUpIcon />
                        <KeyboardDoubleArrowUpIcon />
                        <KeyboardDoubleArrowUpIcon />
                    </a>
                </div>
            </nav>
        </div>
        <div className="mobile-header-btn">
            <div onClick={()=>setLinksVisible(true)}>
                <MenuIcon />
            </div>
        </div>
        <div className={"mobile-links"+(linksVisible?" visible":"")} onClick={()=>setLinksVisible(false)}>
            <nav>
                <ul className={"page-links"+(linksVisible?" visible":"")}>
                    {
                        links.map((link)=>(
                            <li key={link.to}>
                                <NavLink
                                    className={(navData) => (navData.isActive ? 'active' : '')}
                                    to={link.to}
                                    onClick={()=>{setLinksVisible(false);}}
                                >
                                    <Button onClick={()=>{navigate(link.to); setLinksVisible(false);}}>{link.title}</Button>
                                </NavLink>
                                {/*<Button onClick={()=>{navigate(link.to); setLinksVisible(false);}}>{link.title}</Button>*/}
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </div>
        </>    
    )
};

export default Header;